// Environment
$platform-name: "cornerstonecommunitycare";
$assets-path: "../assets/";

// Colours
$_blue: #008FB4;
$_dark-blue: darken($_blue, 7%);
// $_light-blue: lighten($_blue, 7%);
$_light-blue: #00BEEE;
$_pink: #E83182;
$_orange: #EC6415;
$_yellow: darken(#FFCF21, 6.25%);
$_purple: #6C247E;
$_green: #92C84B;

// Colours setup
$brand-primary: $_blue;
$brand-primary-text-contrast-override: #FFF;
$brand-secondary: $_yellow;
$brand-secondary-text-contrast-override: #FFF;

$donate-colour: $_yellow;

// Create greyscale
$black: #000;
$grey-dark: #16161d;
$grey: #444;
$grey-light: #757575;
$grey-lighter: #ECEEEF;
$grey-lightest: #F6F6F6;
$white: #FFF;

// Layout
$container-max-width-large: 1440px;
$container-max-width: 1230px;
$gap-width: 20px;
$site-bleed: 15px;

$border-radius: 4px;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 810px,
  lg: 1024px,
  xl: 1280px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $grey-dark;
$profile-pics-enabled: false;
$border-width: 1px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 0.5s;
$transition-curve: ease-in-out;

// Logo
$logo: "logo.svg";
$logo-width: 125px;
$logo-height: 125px;
$logo-absolute: false;
$logo-breakpoint: map-get($breakpoints, lg);
$logo-mobile: $logo;
$logo-mobile-width: $logo-width * 0.65;
$logo-mobile-height: $logo-height * 0.65;

// Typography
$font-family-base: "Open Sans",  sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: 'Asap', sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.25;
$headings-colour: inherit;
$headings-text-transform: none;
$headings-letter-spacing: initial;

$hr-border-colour: $_blue;
$hr-border-width: 4px;

// Links
$link-colour: $_dark-blue;
$link-decoration: none;
$link-decoration-colour: initial;
$link-hover-decoration: underline;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: text-contrast($btn-background-colour);
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 1.3em;
$btn-padding-y: 0.65em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $headings-font-weight;
$btn-font-size: $font-size-base;
$btn-text-transform: none;
$btn-text-decoration: none;
$btn-line-height: 1.25;
$btn-letter-spacing: inherit;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: text-contrast($donate-colour);

// Social icons
$social-icons-background-colour: $btn-background-colour; // A colour, transparent, or 'brand'
$social-icons-colour: text-contrast($social-icons-background-colour); // A colour, or 'brand'
$social-icons-hover-colour: $social-icons-colour; // A colour, or 'brand'
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.25rem;
$social-icons-gap: 2.5px;
$social-icons-border-radius: $btn-border-radius;
$social-icons-box-shadow: $btn-box-shadow;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $white;
// $social-icons-header-hover-colour: $_light-blue;
$social-icons-header-hover-colour: $_yellow;

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $_yellow;

// Share this page
$share-enabled: true;
$share-text-align: left;
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h5;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;


// Tables
$table-cell-padding: 0.75rem;


// Card
$card-gap-width: $gap-width;
$card-border-radius: 0;
$card-border: 0px;
$card-box-shadow: none;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: transparent;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer;
$card-details-background-colour: transparent;
$card-details-background-gradient: none;
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $white;
$card-heading-font-size: $font-size-h5;
$card-heading-margin-bottom: $spacer * 0.75;
$card-summary-enabled: true;
$card-summary-colour: $body-colour;
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: rgba($black, 0.05);
$card-footer-background-gradient: none; // none or CSS gradient
$card-footer-colour: $body-colour;
$card-footer-padding: 0.5rem $card-details-padding;
$card-min-width: 260px;

// Card basic
$card-basic-image-position: above;

// Card text overlay
// $card-text-overlay-show-summary-on-hover: true;
// $card-text-overlay-summary-always-on: false;
// $card-text-overlay-details-max-width: 100%;
// $card-text-overlay-details-position-y: bottom; // top, center, bottom
// $card-text-overlay-details-position-x: left; // left, center, right
// $card-text-overlay-breakpoint: map-get($breakpoints, sm); // When do you want to break the text under image?

// Card text over
// $card-text-over-image-vertical-text-position: center; // top, center or bottom
// $card-text-over-image-darken-image-amount: 0.5;
// $card-text-over-image-tint-colour: $black;
// $card-text-over-image-show-summary-on-hover: true;

// Card side by side
// $card-side-by-side-image-position: left;
// $card-side-by-side-image-width: 50%;
// $card-side-by-side-grow-image: true; // Do you want the image to scale to 100% height of the overall card?
// $card-side-by-side-vertical-text-position: top; // top, center or bottom
// $card-side-by-side-breakpoint: map-get($breakpoints,
//   sm); // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: $card-box-shadow;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.035;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-details-background-gradient: $card-details-background-gradient; // none or CSS gradient
$card-hover-heading-colour: $card-heading-colour;
$card-hover-summary-colour: text-contrast($card-hover-details-background-colour);

// Event cards
$event-card-floating-date: false;
$event-card-floating-date-background-colour: $card-details-background-colour;
$event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
$event-card-floating-date-border-radius: $border-radius;
$event-card-floating-date-margin-y: $spacer;
$event-card-floating-date-margin-x: $spacer;

// Global alert
$global-alert-background-colour: $brand-primary;
$global-alert-colour: text-contrast($global-alert-background-colour);

// Menu admin
$menu-admin-enabled: false;
// $menu-admin-max-width: 100%; // $container-max-width
// $menu-admin-background-colour: $grey-lighter;
// $menu-admin-background-gradient: none; // none or CSS gradient
// $menu-admin-contents-max-width: $container-max-width; // 100%
// $menu-admin-font-size: $font-size-small;
// $menu-admin-align-items: right; // left, center, right
// $menu-admin-link-colour: $link-colour;
// $menu-admin-absolute: false;
// $menu-admin-login-link-enabled: true;
// $menu-admin-manager-link-enabled: true;
// $menu-admin-my-details-link-enabled: true;
// $menu-admin-logout-link-enabled: true;
// $menu-admin-to-nav-breakpoint: 0px;

// Header
$header-background-colour: $_blue;
$header-background-gradient: none; // none or CSS gradient
$header-box-shadow: $box-shadow;

$header-absolute: false;
$header-absolute-background: rgba($black, 0.25);
$header-absolute-breakpoint: map-get($breakpoints, md);

// Header content
$header-content-max-width: 1440px;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer;
$header-content-padding-bottom: 0;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline
$tagline-enabled: true;
$tagline-font-family: $headings-font-family;
$tagline-font-weight: $font-weight-bold;
$tagline-font-size: $font-size-h4;
$tagline-colour: $headings-colour;
$tagline-above-cta-breakpoint: 0;
$tagline-breakpoint: map-get($breakpoints, md);

// Main call to action
$cta-btn-background-colour: transparent;
$cta-btn-colour: text-contrast($header-background-colour);
$cta-btn-gap: 10px;
$cta-btn-breakpoint: map-get($breakpoints, md);
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: true;
$header-search-margin-right: 10px;
$header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
$header-search-input-max-width: 175px;
$header-search-input-height: 41.59px;
$header-search-input-margin-right: -40px;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: rgba($input-placeholder-colour, 0.75);
$header-search-input-border-radius: $border-radius;
$header-search-input-border-colour: transparent;
$header-search-input-border-width: 0;
$header-search-input-background-colour: $white;
$header-search-input-colour: text-contrast($header-search-input-background-colour);
$header-search-input-focus-background-colour: $white;
$header-search-input-focus-colour: text-contrast($header-search-input-focus-background-colour);

// Search - button
$header-search-button-width: 40px;
$header-search-button-height: 41.59px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: $social-icons-font-size;
$header-search-button-icon-colour: $_blue;
$header-search-button-border-radius: 0;

// Search when in nav
$nav-search-enabled: $header-search-enabled;
$nav-search-input-margin-right: -40px;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
$nav-search-input-border-colour: $header-search-input-border-colour;
$nav-search-input-border-width: 0; // Should already contrast enough with the menu bg
$nav-search-input-background-colour: $header-search-input-background-colour;
$nav-search-button-background-colour: $header-search-button-background-colour;
$nav-search-button-icon-colour: $_blue;

// Social icons
$header-social-icons-margin-right: 10px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: 680px;

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $header-background-colour;
$header-sticky-box-shadow: 0 0 10px rgba($black, 0.1);
$header-sticky-logo: $logo;
$header-sticky-logo-width: $logo-width;
$header-sticky-logo-height: $logo-height;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: true;
$header-sticky-social-icons-enabled: true;
$header-sticky-cta-btns-enabled: true;
$header-sticky-header-content-margin-top: $header-content-margin-top;
$header-sticky-header-content-margin-bottom: $header-content-margin-bottom;
$header-sticky-nav-margin-bottom: 0;

// Navigation
$nav-type: normal; // normal, flyout, fullscreen // TODO: mega
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: transparent;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 10px $site-bleed $spacer;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: 1.025em;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $white;
$nav-top-level-item-hover-background-colour: $_dark-blue;
$nav-top-level-chevrons-enabled: false;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour;
$nav-top-level-first-item-home-icon: false;
$nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $_dark-blue;
$nav-submenu-box-shadow: 0px 4px 4px 0px rgba($black, 0.35);
$nav-submenu-border-radius: $border-radius;
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $nav-top-level-item-font-size;
$nav-submenu-item-font-weight: $font-weight-base;
$nav-submenu-item-hover-colour: $white;
$nav-submenu-item-hover-background-colour: $_blue;
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $white;

// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $white;
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: null; // What viewport width do you want to remove the 'menu' text from the burger button?
$burger-btn-bar-breakpoint: 680px;

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: $spacer * -2;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: 1440px;
$nav-normal-align-items: right;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $_dark-blue;
$nav-normal-mobile-top-level-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-submenu-background-colour: $_blue;
$nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: text-contrast($nav-normal-mobile-background-colour);

// Nav - Parent nav items clickable
$nav-parent-clickable-enabled: true;
$nav-parent-clickable-chevron-background-colour: lighten($_dark-blue, 5%);
$nav-parent-clickable-chevron-colour: $white;
$nav-parent-clickable-chevron-icon-type: "chevron";
$nav-parent-clickable-chevron-active-background-colour: $_blue;
$nav-parent-clickable-chevron-active-colour: $white;

// Specifically when no banner present and you have gone for $header-absolute: true
// $no-banner-header-background-colour: $header-background-colour;
// $no-banner-logo: $logo;
// $no-banner-logo-retina: $no-banner-logo;
// $no-banner-tagline-colour: $tagline-colour;
// $no-banner-social-icons-header-background-colour: $social-icons-header-background-colour; // A colour, transparent, or 'brand'
// $no-banner-social-icons-header-colour: $social-icons-header-colour; // A colour, or 'brand'
// $no-banner-social-icons-header-hover-colour: $social-icons-header-hover-colour; // A colour, or 'brand'
// $no-banner-nav-background-colour: $nav-background-colour;
// $no-banner-nav-top-level-item-colour: $nav-top-level-item-colour;
// $no-banner-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $no-banner-header-search-input-placeholder-colour: $header-search-input-placeholder-colour;
// $no-banner-header-search-input-border-colour: $header-search-input-border-colour;
// $no-banner-header-search-input-background-colour: $header-search-input-background-colour;
// $no-banner-header-search-button-background-colour: $header-search-button-background-colour;
// $no-banner-header-search-button-icon-colour: $header-search-button-icon-colour;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: transparent;
$carousel-contents-max-width: 100%;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: center;
$carousel-details-position-x: left;
$carousel-details-background-colour: transparent;
// $carousel-details-background-colour: rgba($_blue, 0.65);
$carousel-details-box-shadow: $box-shadow;
$carousel-details-max-width: 500px;
$carousel-details-min-height: 100%;
$carousel-details-padding: 0;
$carousel-details-margin-y: 0;
$carousel-details-margin-x: 5vw;
$carousel-details-border-radius: 0;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $white;
$carousel-heading-font-size: 2.25em;
$carousel-summary-enabled: true;
$carousel-summary-colour: $white;
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: $_blue;
$carousel-read-more-border-colour: $btn-border-colour;
$carousel-read-more-colour: $btn-text-colour;
$carousel-read-more-font-size: $font-size-base;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: true;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 0px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: 0;
$carousel-controls-button-box-shadow: none;
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-y: 0;
$carousel-controls-margin-x: 0;
$carousel-controls-icon-colour: $white;
$carousel-controls-icon-font-size: 1.45rem;
$carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;
// $carousel-buttons-type: dots;

// Carousel buttons dots
// $carousel-dots-position-y: bottom; // top, bottom
// $carousel-dots-position-x: center;
// $carousel-dots-padding: $spacer;
// $carousel-dots-colour: $white;
// $carousel-dots-opacity: 0.65;
// $carousel-dots-active-colour: $_light-blue;

// Carousel video play button (before breakpoint, when carousel details present)
$carousel-video-btn-position-y: 50%;
$carousel-video-btn-position-x: 50%;

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
// $carousel-scroll-btn-size: $carousel-controls-button-size;
// $carousel-scroll-btn-background-colour: $btn-background-colour;
// $carousel-scroll-btn-border-radius: $border-radius;
// $carousel-scroll-btn-box-shadow: $box-shadow;
// $carousel-scroll-btn-icon-colour: $btn-text-colour;
// $carousel-scroll-btn-icon-font-size: $social-icons-font-size;
// // $carousel-scroll-btn-icon: '\f078'; // TODO, similar issue to $carousel-controls-next-icon
// $carousel-scroll-btn-margin-y: 15%; // Can use a negative value to get it to peek over the edge
// $carousel-scroll-btn-animation-prefab: 0; // Pick a number from 0 (off) to 3!

// Carousel background YouTube video
$carousel-yt-background-enabled: false;
// $carousel-yt-background-size: "cover";
// $carousel-yt-cover-padding-bottom: 56.25%;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $brand-primary;
$carousel-mobile-heading-colour: text-contrast($carousel-mobile-details-background-colour);
$carousel-mobile-summary-colour: text-contrast($carousel-mobile-details-background-colour);

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: 200px;


// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: $grey-lightest;
// $home-intro-background-colour: transparent;
$home-intro-background-gradient: none; // none or CSS gradient
$home-intro-background-image: null;
$home-intro-background-image-opacity: 0.5;
$home-intro-box-shadow: $box-shadow;
$home-intro-content-max-width: 1000px;
$home-intro-content-align: center; // left, center or right
$home-intro-padding-y: $spacer * 3;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-text-transform: $headings-text-transform;
$home-intro-font-family: $font-family-base; // Will affect all elements within the home intro snippet
$home-intro-font-size: $font-size-base;
$home-intro-font-weight: $font-weight-base;
$home-intro-line-height: $line-height-base;
$home-intro-colour: $body-colour;
$home-intro-border-radius: 0;
$home-intro-mobile-padding-y: $home-intro-padding-y * .7;
$home-intro-mobile-font-size: $home-intro-font-size * .85;


// Home headlines intro$home-headlines-intro-enabled: false;
$home-headlines-intro-snippet-title-enabled: false;
// $home-headlines-intro-max-width: 100%;
// $home-headlines-intro-content-max-width: $container-max-width;
// $home-headlines-intro-background-colour: $white;
// $home-headlines-intro-padding-top: $spacer * 4;
// $home-headlines-intro-padding-bottom: 0;
// $home-headlines-intro-margin-top: 0;
// $home-headlines-intro-margin-bottom: 0;
// $home-headlines-intro-text-align: center;
// $home-headlines-intro-text-transform: $headings-text-transform;
// $home-headlines-intro-font-family: $headings-font-family;
// $home-headlines-intro-font-size: $font-size-h2;
// $home-headlines-intro-colour: text-contrast($home-headlines-intro-background-colour);

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: $white;
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 3;
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;

// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: $spacer;
$impact-stats-padding-y: $spacer * 5;
$impact-stats-background-colour: $_blue;
$impact-stats-background-gradient: none;
$impact-stats-background-image: "db7693ea-5148-4b70-9072-a2a978e49352.jpg";
$impact-stats-background-image-opacity: 0.15;
$impact-stats-background-image-size: cover;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: 0;
$impact-stats-colour: text-contrast($impact-stats-background-colour);
$impact-stats-text-align: left;
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints, md);

$impact-stats-overall-container-direction: column;
$impact-stats-direction: row; // Do you want the stats in a row or column?
$impact-stats-mobile-text-align: left;

// Heading
$impact-stats-heading-enabled: true; //true or false
$impact-stats-heading-font-size: $font-size-h1;
$impact-stats-heading-text-align: $impact-stats-text-align; // left, center, right
$impact-stats-heading-colour: $white;
$impact-stats-heading-margin-bottom: $spacer * 2;

// Individual stat
$impact-stat-min-width: 180px; // Use this to control how many you get in a row
$impact-stat-direction: column; // column, row
$impact-stat-padding: $spacer $site-bleed;
$impact-stat-margin: 0;
$impact-stat-border: 0px solid $border-colour; // Style border here
$impact-stat-border-width: 0 0 0 0; // Set border widths (t-r-b-l) here

// Figure
$impact-stats-figure-colour: $_yellow;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 4.25em;

// Summary
$impact-stats-summary-colour: $white;
$impact-stats-summary-font-size: $font-size-large;
$impact-stats-summary-font-weight: $font-weight-bold;
$impact-stats-summary-margin-top: $spacer * 0.25;

// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $white;
$home-feeds-padding-top: $spacer * 4;
$home-feeds-padding-bottom: $spacer * 4;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h3;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: left; // left, center, right
$feeds-title-text-transform: $headings-text-transform;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: true;

// Twitter feed
// $home-feed-twitter-enabled: false;
// $home-feed-twitter-card-gap-width: $card-gap-width;
// $home-feed-twitter-card-border: $card-border;
// $home-feed-twitter-card-border-radius: $card-border-radius;
// $home-feed-twitter-card-box-shadow: $card-box-shadow;
// $home-feed-twitter-card-text-align: $card-text-align;
// $home-feed-twitter-card-padding: $card-details-padding;
// $home-feed-twitter-card-background-colour: $card-details-background-colour;
// $home-feed-twitter-card-colour: text-contrast($home-feed-twitter-card-background-colour);
// $home-feed-twitter-card-min-width: $card-min-width;

// Footer
$footer-boxes-count: 4;
$footer-prefab: 6;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer;
$footer-background-colour: $grey-lightest;
$footer-background-gradient: none; // none or CSS gradient
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: $_dark-blue;
$footer-link-decoration: none;
$footer-link-hover-colour: darken($footer-link-colour, 10%);
$footer-link-hover-decoration: underline;
$footer-text-align: left;
$footer-font-size: $font-size-base;
$footer-headings-font-size: $font-size-h4;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: $_blue;
$newsletter-background-gradient: none; // none or CSS gradient
$newsletter-colour: $white;
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h4;
$newsletter-heading-text-align: left;
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 2;
$newsletter-heading-colour: $white;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $white;
$newsletter-button-colour: $_blue;
$newsletter-button-border-colour: $_blue;
$newsletter-use-placeholders: false;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 2.5;
$newsletter-hero-contents-max-width: $footer-contents-max-width;


// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: true;
$donors-list-profile-pics-enabled: true;

// Posts
$post-content-max-width: $container-max-width;
$post-body-background-colour: transparent;

// Header text
$header-text-margin-top: $spacer * 3;
$header-text-margin-bottom: $spacer * 1.5;


// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $headings-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left;
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;
$page-title-md-font-size: $page-title-font-size * 0.8;
$page-title-sm-font-size: $page-title-font-size * 0.6;

// Page title over banner
$page-title-over-banner-enabled: false;
// $page-title-over-banner-breakpoint: map-get($breakpoints, md);
// $page-title-over-banner-background-colour: $brand-primary;
// $page-title-over-banner-colour: text-contrast($page-title-over-banner-background-colour);
// $page-title-over-banner-breadcrumb-enabled: true;


// Sidebar
$sidebar-enabled: true;
$sidebar-layout: adjacent;
$sidebar-mobile-layout: below;
$sidebar-adjacent-width: 280px;
$sidebar-adjacent-gap: $spacer * 5;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: true;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-heading-colour: $link-colour;
$associated-item-headings-text-transform: $headings-text-transform;
$associated-item-summary-enabled: true;
$associated-item-border: false;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-body-background-colour: transparent;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width; // Either a px value or 100%. Adjust this value to dictate how many listing items you want in a single row

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: true;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: true;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: false;
$breadcrumb-margin-top: 0;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align;

// Blockquote
$blockquote-max-width: $post-content-max-width;
$blockquote-background-colour: $brand-primary;
$blockquote-background-gradient: none;
$blockquote-border-radius: $border-radius;
$blockquote-margin-y: #{$spacer * 3};
$blockquote-padding: #{$spacer * 2};
$blockquote-text-align: left;
$blockquote-font-size: $font-size-large;
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: text-contrast($blockquote-background-colour);
$blockquote-box-shadow: none;

// Fundraising
$fundraising-enabled: true;

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: true;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: true;

// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: false;
$donation-form-frequency-tabs: true;

// Donation amounts
$donation-amount-min-width: 220px;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: $context-container-background-colour;
$quick-giving-background-gradient: none; // none or CSS gradient
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: $context-container-border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: rgba($white, 0.75);
$quick-giving-donation-amount-figure-colour: rgba($body-colour, 0.75);
$quick-giving-donation-amount-border-width: $btn-border-width;
$quick-giving-donation-amount-border-colour: $donate-colour;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: $donate-colour;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: overlay;
$home-quick-giving-max-width: 100vw;
$home-quick-giving-content-max-width: calc(100% - 10vw);
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 5;
$home-quick-giving-background-colour: $quick-giving-background-colour;
$home-quick-giving-background-gradient: $quick-giving-background-gradient; // none or CSS gradient
$home-quick-giving-background-image: null;
$home-quick-giving-background-image-opacity: 0.5;
$home-quick-giving-donation-amount-background-colour: rgba($white, 0.75);
$home-quick-giving-donation-amount-figure-colour: rgba($body-colour, 0.75);
$home-quick-giving-donation-amount-border-width: 0px;
$home-quick-giving-donation-amount-border-colour: transparent;
$home-quick-giving-donation-amount-selected-background-colour: $white;
$home-quick-giving-donation-amount-selected-figure-colour: $donate-colour;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donate-btn-colour: text-contrast($donate-colour);
$home-quick-giving-donate-btn-border-colour: $btn-border-colour;
$home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size;
$home-quick-giving-colour: $white;
$home-quick-giving-heading-colour: $headings-colour;
$home-quick-giving-heading-font-size: $font-size-h1;

// Adjacent homepage quick giving
$home-quick-giving-type-adjacent-image-side: left; // If type is adjacent, which side do you want the image

// Overlay homepage quick giving
$home-quick-giving-type-overlay-items-side: left; // If type is overlay, which side do you want the copy and donation items?
$home-quick-giving-type-overlay-gradient: rgba($grey-dark, 0.45);

// Button actions
$button-actions-padding: 0;
$button-actions-background-colour: transparent;
$button-actions-align-buttons: left;

// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal;
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;

// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: true;

// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour;
$blog-details-padding: $context-container-padding;
$blog-details-border-radius: $context-container-border-radius;
$blog-details-profile-pic-enabled: false;

// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $context-container-background-colour;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: $context-container-border-radius;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: $btn-background-colour;
$faq-item-btn-chevron-colour: $white;
$faq-item-hover-btn-background-colour: $_dark-blue;
$faq-item-hover-btn-chevron-colour: $white;


// In Memory
$listing-inmem-masonry-layout: true;


// Shop
$shop-enabled: false;

// Subsite
$subsite-enabled: false;
// $subsite-hide-main-header: false;
// $subsite-hide-main-tagline: false;
// $subsite-hide-header-search: false;
// $subsite-hide-main-cta: false;
// $subsite-content-max-width: $container-max-width;
// $subsite-title-font-size: $page-title-font-size;

// Subsite logo
// $subsite-logo: "logo.svg"; // Set to 'title' if you want to just use the subsite text title. If you use a logo image, it will act as a link back to the subsite's homepage
// $subsite-logo-retina: $subsite-logo;
// $subsite-logo-width: 120px;
// $subsite-logo-height: 120px;
// $subsite-logo-margin: $header-content-margin-top 0 0;

// Subsite nav
// $subsite-nav-breakpoint: $nav-breakpoint;
// $subsite-nav-max-width: $nav-normal-max-width;
// $subsite-nav-margin-top: $spacer;
// $subsite-nav-margin-bottom: $spacer;
// $subsite-nav-background-colour: $nav-background-colour;
// $subsite-nav-contents-max-width: $container-max-width;
// $subsite-nav-align-items: $nav-normal-align-items; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding;
// $subsite-nav-top-level-item-colour: $nav-top-level-item-colour;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour;

// $subsite-nav-submenu-width: $nav-submenu-width;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour;

// Cookie consent
// $cookie-consent-position: bottom; // Bottom or top
// $cookie-consent-background-colour: $black; // You can use rgba here for opacity
// $cookie-consent-colour: text-contrast($cookie-consent-background-colour);
// $cookie-consent-font-family: initial;
// $cookie-consent-btn-background-colour: $btn-background-colour;
// $cookie-consent-btn-text-colour: $btn-text-colour;


// Keeping In Touch
$keeping-in-touch-enabled: true;

// Keeping In Touch
$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: true;
$keeping-in-touch-via-sms-enabled: true;
$keeping-in-touch-via-post-enabled: true;

// Slick
// $slick-enabled: false;
