// Home features - Layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 2
  );

  // Home features - Card style
  .homeFeature {
    @include card;
    @include card-basic;
  }
}


// Home feeds - Overall Layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 2
);

// Home feed 1 - Layout
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 4
  );
  .feedItem {
    @include card;
    @include card-basic;
  }
}

// Home feed 2 - Layout
.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  );
  .feedItem {
    @include card;
    @include card-basic;
  }
}

// Home feed 3 - Layout
.homeFeedBox3 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  );
  .feedItem {
    @include card (
      $card-text-align: center,
      $card-heading-colour: $body-colour,
      $card-hover-heading-colour: $body-colour,
      $card-details-padding: $spacer*2 $spacer
    );
    @include card-basic;
  }
}

// Home feeds - Card style



// Listed posts
.listedPost:not(.listedProduct):not(.listedSearchResult) {
  @include card;
  @include card-basic;
}


// Listed products
.listedProduct {
  @include card;
  @include card-basic;
}