// Fix horizontal scrollbar
body {
  overflow-x: hidden;
}

// Header Desktop - Overall Layout
.pageHeader {
  // Header content - layout
  .headerContent {
    align-items: flex-start;
    z-index: 1;
    
    // Main Call to Action - Position
    .mainCallToAction {
      z-index: 2;
      margin-top: $spacer * 0.75;
      margin-left: auto;
    }
  }
}

// Header Tablet - Overall layout
@media (max-width: $logo-breakpoint) {
  // Header - Layout
  .pageHeader .headerContent {
    padding-top: $spacer * 0.5;
    padding-bottom: $spacer * 0.5;
    padding-left: $site-bleed;
    padding-right: $site-bleed;
    align-items: center;
  }
  // Header sticky - Layout
  .pageHeader.sticky .mainLogo {
    width: $logo-mobile-width;
    height: $logo-mobile-height;
  }
}


// Main Call to Action - Items order
.pageHeader .headerContent .mainCallToAction {
  ul.socialIcons { order: 1; }
  .mainCallToActionButtons { order: 3; }
  .menuMainAlt { order: 4; }
  .header-search {
    flex-grow: 0;
    order: 2;
  }
}


// Header background - Setup
.mainLogo {
  position: relative;
  z-index: unset;
}

// Header - Background
.mainLogo:after {
  content: '';
  position: absolute;
  top: 50%;
  right: $spacer * -4;
  transform: translateY(-50.25%);
  height: 160px;
  width: 1000px;
  background-image: url($assets-path + "header-background_white.svg");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  z-index: -1;
  @media (max-width: $logo-breakpoint) {
    right: $spacer * -2;
    height: 97px;
  }
  @media (max-width: $burger-btn-bar-breakpoint) {
    height: 117px;
  }
}


// Header Mobile - Overall
@media (max-width: $burger-btn-bar-breakpoint) {
  // Header Mobile - Layout
  .pageHeader .headerContent {
    padding-bottom: calc(35px + #{$spacer * 0.5});
  }
  // Header Mobile - CTA Button size
  .mainCallToAction .cta-button.persist:not([class*="donate"]),
  .mainCallToAction .cta-button.persist.donate {
    display: block;
    font-size: 0.8rem;
    min-width: 136px;
    // margin-left: 0;
    margin: 4.5px 0 4.5px 0;
  }
}


// Burger button - colour
.menuMainAlt {
  background-color: transparent;
  color: $burger-btn-text-colour;
  margin: 0;
  @media (max-width: $burger-btn-bar-breakpoint) {
    background-color: $_dark-blue;
    height: 35px;
    &.active, &:hover {
      background-color: darken($_dark-blue, 5%);
    }
  }
}



// Header CTA
.mainCallToAction a.cta-button:not(.donate) {
  box-shadow: inset 0 0 0 2px $white;
  &:hover {
    background-color: $white;
    color: $_blue;
  }
}


// Header - Phone number
a.phoneNumber {
  position: relative;
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  color: $white;
  width: auto;
  &:before {
    content: '\f095';
    font-family: $icon-font-family;
    display: inline-block;
    margin-right: 10px;
  }
}


// Nav - Disable duplicated search bar
.menuMain .header-search:first-of-type {
  display: none;
}


// Nav - Top level nav on hover
@media  (min-width: #{$nav-breakpoint  + 1px}) {
  .menuMain ul.topLevel > li  > a {
    border-radius: $border-radius $border-radius  0 0;
  }
}

// Accessibility widget

.at-content {
  z-index: 2!important;
}
.altStyle1, .altStyle2 {
  .mainLogo:after {
    background-image: none;
  }
}
.altStyle1 {
  .socialIcons a.phoneNumber {
    color: white!important;
  }
  .carouselControls a:before {
    color: white;
  }
  .homeImpactWrapper>h2,
  .footerBox[class*="ewsletter"] label {
    color: black;
  }
  .homepage-quickgiving--container {
    h2 {
      color: white;
    }
    .formQuestion.donationAmount:not(.ccAmount).donationSelected .donationAmountFigure {
      color: black;
    }
  }
  .footerBox[class*=links]:before {
    background-color: white;
  }
  a.cta-button.green span {
    color: white;
  }
}
.altStyle2 {
  .socialIcons a.phoneNumber {
    color: black!important;
  }
  .carouselControls a:before {
    color: black;
  }
  .homepage-quickgiving--container {
      .formQuestion.donationAmount:not(.ccAmount) .donationAmountFigure {
        color: black;
      }
  }
  .footerBox[class*=links]:before {
    background-color: black;
  }
  a.cta-button.green span {
    color: black;
  }
}

// Carousel - Pseudo
@media (min-width: #{$carousel-breakpoint + 1px}) {
  .carouselSlide:before {
    content: '';
    position: absolute;
    width: 50vw;
    height: 50vw;
    top: 50%;
    left: 12.5%;
    min-width: calc(#{$carousel-details-max-width} + #{$carousel-details-margin-x * 2});
    min-height: calc(#{$carousel-details-max-width} + #{$carousel-details-margin-x * 2});
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: rgba($black, 0.45);
    border-radius: 5%;
    z-index: 1;
  }
  .carouselSlideTitle { z-index: 0; }
  .carouselDetailWrapper { z-index: 2;}
}


// Disable carousel transition	// Disable carousel transition
.carousel {
  .carousel {
    transition: 0 all !important;
    transition: 0 all !important;
  }
}


// Carousel heading - Spacing
.carouselSlideHeading {
  margin-bottom: 0.25em;
}


// Carousel mobile - Disable slide buttons
@media (min-width: #{$carousel-breakpoint + 1px}) {
  .carouselControls { opacity: 0; }
  .carousel:hover .carouselControls { opacity: 1; }
}


// Card overall - Details position
.homeFeatures .homeFeature:not(.homeFeatureNoBanner) .homeFeatureDetailsWrapper,
.homeFeed .feedItem:not(.feedItemNoBanner) .feedItemDetailsWrapper,
.listedPost:not(.listedPostNoBanner) .listedPostText {
  transform: translateY(-2rem);
}
.listedPost.listedMapItem:not(.listedPostNoBanner) .listedPostText {
  transform: translateY(-1.5rem);
}

// Card overall - Heading highlighter
.homeFeatures .homeFeature .homeFeatureDetailsWrapper,
.homeFeed .feedItem .feedItemDetailsWrapper,
.listedPost .listedPostText {
  h2 a,
  h3 a {
    display: inline;
    box-decoration-break: clone;
    padding: $spacer*0.5 $spacer;
    line-height: 1.55;
    border-radius: $border-radius;
  }
}

// Card specific - Heading colour
.homeFeatures .homeFeature.homeBox1 .homeFeatureDetailsWrapper h2 a { background-color: $_blue; }
.homeFeatures .homeFeature.homeBox1:hover .homeFeatureDetailsWrapper h2 a { background-color: darken($_blue, 10%); }

.homeFeatures .homeFeature.homeBox2 .homeFeatureDetailsWrapper h2 a { background-color: $_yellow; }
.homeFeatures .homeFeature.homeBox2:hover .homeFeatureDetailsWrapper h2 a { background-color: darken($_yellow, 10%); }

.homeFeatures .homeFeature.homeBox3 .homeFeatureDetailsWrapper h2 a { background-color: $_blue; }
.homeFeatures .homeFeature.homeBox3:hover .homeFeatureDetailsWrapper h2 a { background-color: darken($_blue, 10%); }

.homeFeatures .homeFeature.homeBox4 .homeFeatureDetailsWrapper h2 a { background-color: $_yellow; }
.homeFeatures .homeFeature.homeBox4:hover .homeFeatureDetailsWrapper h2 a { background-color: darken($_yellow, 10%); }

.homeFeedBox1 .feedItem .feedItemDetailsWrapper h3  a { background-color: #36B2EA; }
.homeFeedBox1 .feedItem:hover .feedItemDetailsWrapper h3  a { background-color: darken(#36B2EA, 10%); }

.homeFeedBox2 .feedItem .feedItemDetailsWrapper h3  a { background-color: $_orange; }
.homeFeedBox2 .feedItem:hover .feedItemDetailsWrapper h3  a { background-color: darken($_orange, 10%); }

.listedPost .listedPostText h2 a { background-color: $_blue; }
.listedPost:hover .listedPostText h2 a { background-color: darken($_blue, 10%); }

.blogsListingPage .listedPost .listedPostText h2 a { background-color: $_orange; }
.blogsListingPage .listedPost:hover .listedPostText h2 a { background-color: darken($_orange, 10%); }

.blogsListingPage .listedPost .listedPostText h2 a[href="/Blogs/supporter-spotlight"] { background-color: $_yellow; }
.blogsListingPage .listedPost:hover .listedPostText h2 a[href="/Blogs/supporter-spotlight"] { background-color: darken($_yellow, 10%); }

.listedPost.module-blog .listedPostText h2 a { background-color: $_orange; }
.listedPost.module-blog:hover .listedPostText h2 a { background-color: darken($_orange, 10%); }

.listedPost.listedAppeal .listedPostText h2 a,

.listedPost.PostCategory_fundraising-ideas .listedPostText h2 a { background-color: $_yellow; }
.listedPost.PostCategory_fundraising-ideas:hover .listedPostText h2 a { background-color: $_yellow; }

.listedPost.listedAppeal:hover .listedPostText h2 a,
.fundraising-ideas .listedPost:hover .listedPostText h2 a { background-color: darken($_yellow, 10%); }

.listedPost.listedFundraiser .listedPostText h2 a { background-color: $_yellow; }
.listedPost.listedFundraiser:hover .listedPostText h2 a { background-color: darken($_yellow, 10%); }

body.blogs-supporter-spotlight .listedPost .listedPostText h2 a { background-color: $_yellow; }
body.blogs-supporter-spotlight .listedPost:hover .listedPostText h2 a { background-color: darken($_yellow, 10%); }

.listedPost.PostCategory_challenge-events .listedPostText h2 a { background-color: $_yellow; }
.listedPost.PostCategory_challenge-events:hover .listedPostText h2 a { background-color: darken($_yellow, 10%); }

.listedPost.PostCategory_how-you-can-change-lives .listedPostText h2 a { background-color: $_yellow; }
.listedPost.PostCategory_how-you-can-change-lives:hover .listedPostText h2 a { background-color: darken($_yellow, 10%); }

.listedPost.PostCategory_fundraise-for-us .listedPostText h2 a { background-color: $_yellow; }
.listedPost.PostCategory_fundraise-for-us:hover .listedPostText h2 a { background-color: darken($_yellow, 10%); }

.listedPost.PostCategory_ways-to-give .listedPostText h2 a { background-color: $_yellow; }
.listedPost.PostCategory_ways-to-give:hover .listedPostText h2 a { background-color: darken($_yellow, 10%); }

.listedPost.module-news .listedPostText h2 a { background-color: $_light-blue; }
.listedPost.module-news:hover .listedPostText h2 a { background-color: darken($_light-blue, 10%); }

.listedPost.PostCategory_our-stories .listedPostText h2 a { background-color: $_orange; }
.listedPost.PostCategory_our-stories:hover .listedPostText h2 a { background-color: darken($_orange, 10%); }


// Home Intro - Text sizes
.homeIntro span {
  font-size: 2.25em;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height; 
  // color: $_dark-blue;
}


// Homepage stats - Heading layout
.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
  padding: 0$site-bleed;
}


// Homepage stats - Font family
.homefeaturecategory-homeboximpactstats td:last-child {
  font-family: $headings-font-family;
}


// Home Feeds - Layout
@media (min-width: map-get($breakpoints, md) + 1px) {
  .homeFeedBox1 { flex: 2; }
}


// Homepage quick giving - Layout
.homefeaturecategory-homeboxquickgiving { overflow: hidden; }
.homefeaturecategory-homeboxquickgiving:before {
  top: 50%;
  left: 12.5%;
  bottom: auto;
  right: auto;
  width: 50vw;
  height: 50vw;
  min-width: calc(#{$carousel-details-max-width} + #{$carousel-details-margin-x * 2});
  min-height: calc(#{$carousel-details-max-width} + #{$carousel-details-margin-x * 2});
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 5%;
  left: 12.5%;
  z-index: 1;
}


// Homepage quick giving - Desktop layout
@media (min-width: map-get($breakpoints, lg) + 1px) {
  .homepage-quickgiving--container {
    padding: 0 5vw;
  }
}


// Homepage quick giving - Mobile
@media (max-width: 1024px) {
  .homefeaturecategory-homeboxquickgiving h2 {
    color: $body-colour;
  }
}


// Feeds Title - Overall pseudo
.feedsTitle {
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    margin-right: $spacer * 0.5;
  }
}


// Feeds Title - Specific
.homeFeedBox1 .feedsTitle {
  // Hover state
  &:hover {
    color: #36B2EA;
  }
  // Pseudo colour
  &:before { background-image: url($assets-path + "square_light-blue.svg"); }
}


// Feeds Title - Specific
.homeFeedBox2 .feedsTitle {
  // Hover state
  &:hover { color: $_orange; }
  // Pseudo colour
  &:before { background-image: url($assets-path + "square_orange.svg"); }
}


// Home Feeds Overall - Layout
.homeFeedsWrapper {
  padding-bottom: 0;
}


// Home Feed Feedback - Layout
.homeFeedBox3.homeFeedsnippets {
  position: relative;
  z-index: 1;
  flex: unset;
  width: 100%;
  
  // Mobile
  @media (max-width: map-get($breakpoints, md)) {
    margin-bottom: 0;
  }

  // Remove FeedTitle
  .feedsTitle {
    display: none;
  }

  // Width
  .feedItem {
    max-width: $post-content-max-width;
    margin: 0 auto;
  }

  // Button
  .cta-button {
    margin-top: $spacer;
  }

  // Background
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background-color: $grey-lightest;
    z-index: -1;
  }
}


// Feeds Title - Hover
.homeFeed > a:hover {
  text-decoration: none;
}


// Newsletter - Font
.footerBox[class*="ewsletter"] label {
  font-weight: $font-weight-bold;
  color: $newsletter-heading-colour;
}


// Footer links - Layout
.footerBox[class*='links'] {
  position: relative;
  margin-top: 0;
  padding: $spacer*0.5 0;
  z-index: 1;
  display:  flex;
  align-items: center;
  // Responsive
  @media (max-width: map-get($breakpoints, lg)) {
    flex-direction: column;
    align-items: flex-start;
  }
  // Background
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background-color: $_dark-blue;
    z-index: -1;
  }
}


@media (min-width: map-get($breakpoints, lg) + 1px) {
  .footerBox[class*='links'] ul.socialIcons {
    margin-left: auto;
  }
}


// Footer links - List
.footerBox[class*='links'] ul.footerLinks  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  li { margin: 0 10px; }
  a {
    font-weight: $font-weight-bold;
    color: text-contrast($_blue);
  }
  // Footer links - Mobile
  @media (max-width: map-get($breakpoints, lg)) {
    flex-direction: column;
    margin-bottom: $spacer;
    li { margin: 5px 0; }
  }
}


//  Donate button
.button.donateBtn {
  background-color: $donate-colour;
  &:hover { background-color: darken($donate-colour, 10%); }
}


// Quick Giving Panel - Donation selected
.quickGivingPanel .donationAmount.donationSelected {
  box-shadow: inset 0px 0px 0px 2px $donate-colour;
}


// Blockquotes - Layout
blockquote:not([class*='arrow']) {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 80px;
    width: 80px;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    background-image: url($assets-path + "square_blue-light-white.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    @media (max-width: map-get($breakpoints, md)) {
      width: 70px;
      height: 70px;
      transform: translate(-40%, -40%);
    }
  }
  // Blockquotes - colours
  &[class*='blue'] {
    background-color: $_blue;
    color: text-contrast($_blue);
    &:after { background-image: url($assets-path + "square_blue-light-white.svg"); }
  }
  &[class*='dark-blue'] {
    background-color: $_dark-blue;
    color: text-contrast($_dark-blue);
    &:after { background-image: url($assets-path + "square_blue-light-white.svg"); }
  }
  &[class*='light-blue'] {
    background-color: $_light-blue;
    color: text-contrast($_light-blue);
    &:after { background-image: url($assets-path + "square_blue-white.svg"); }
  } 
  &[class*='yellow'] {
    background-color: $_yellow;
    color: text-contrast($_yellow);
    &:after { background-image: url($assets-path + "square_yellow-white.svg"); }
  } 
  &[class*='orange'] {
    background-color: $_orange;
    color: $white;
    &:after { background-image: url($assets-path + "square_orange-white.svg"); }
  } 
  &[class*='green'] {
    background-color: $_green;
    color: text-contrast($_green);
    &:after { background-image: url($assets-path + "square_green-white.svg"); }
  } 
}


// Blockquotes - colours
blockquote[class*='arrow'] {
  @media (min-width: map-get($breakpoints, sm) + 1px) {
    position: relative;
    border-radius: $blockquote-border-radius 0 0 $blockquote-border-radius;
    padding: #{$spacer * 1.5};
    padding-right: 80px;
    &:after {
      content: '';
      position: absolute;
      height: calc(100% + 1px);
      width: 80px;
      top: 50%;
      right: -1px;
      transform: translateY(-50%);
      background-image: url($assets-path + "arrow-right-white.png");
      background-position: center right;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  &[class*='blue'] {
    background-color: $_blue;
    color: text-contrast($_blue);
  }
  &[class*='dark-blue'] {
    background-color: $_dark-blue;
    color: text-contrast($_dark-blue);
  }
  &[class*='light-blue'] {
    background-color: $_light-blue;
    color: text-contrast($_light-blue);
  } 
  &[class*='yellow'] {
    background-color: $_yellow;
    color: text-contrast($_yellow);
  } 
  &[class*='orange'] {
    background-color: $_orange;
    color: $white;
  } 
  &[class*='green'] {
    background-color: $_green;
    color: text-contrast($_green);
  } 
}





// Header - Post no banner
body.page-no-banner .pageHeader {
  box-shadow:  $header-sticky-box-shadow;
}


@media (max-width: map-get($breakpoints, sm)) {
  blockquote {
    font-size: 1.25rem;
  }
}


// Map search
.mapResultList {
  display: flex  !important;
  .listedPost.listedMapItem {
    @include card-prefab-flex-column(3);
    @media (max-width: map-get($breakpoints, md)) {
      @include card-prefab-flex-column(2);
    }
    @media (max-width: map-get($breakpoints, sm)) {
      @include card-prefab-flex-column(1);
    }
  }
}


// All inner page image
.contentBlockWrapper .contentBlock .postContent img:not(.commentProfilePic) {
  border-radius: $border-radius;
}


// Maintains aspect ratio 16:9 for video on mobile
.postVideoObjectEmbed,
.embed-container:not(.videoPopUpWrapper) {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: $spacer 0;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $border-radius;
  }
}


// Our breanches (map page) - Layout
body.our-branches {
  .post .contentBlockWrapper,
  .post .headerText .title,
  .postContent {
    max-width: $container-max-width;
  }
}


// Donation form completed page
.pageWrapper.formDonatePageThankYou .post {
  padding-top: 1px;
}


// Buttons - Pseudo overall
.cta-button, .button, .readMore,
.groupSignUpBtn, .addToCalender, .callToActionBtn {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 450ms all ease-in-out;

  // Pseudo setup
  &:before { 
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0px;
    height: 0px;
    background-color: darken($btn-background-colour, 10%);
    border-radius: $border-radius;
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: center center;
    z-index: -1;
    transition: 450ms all ease-in-out;
  }

  // Disable default hover effect
  &:hover {
    background-color: $btn-background-colour;
  }

  // Pseudo hover setup
  &:hover:before {
    transition: 450ms all ease-in-out;
    width: 270px;
    height: 270px;
  }
}


// Donate button - Pseudo
.cta-button.donate, .button.donate,
button.donate, .donateBtn,
.callToActionBtn[class*='undraise']  {
  &:hover { background-color: $donate-colour; }
  &:before { background-color: darken($donate-colour, 10%); }
}

.button[class*='underaise'], .callToActionBtn.fundraise, .findAFundraiser .setUpFundraiserBtn {
  background-color: $donate-colour;
}

// Header ghost button - Pseudo
.mainCallToAction a.cta-button:not(.donate) {
  &:hover { background-color: transparent; }
  &:before { background-color: $white; }
}

// Newsletter button - Pseudo
.footerBox[class*="ewsletter"] button {
  &:hover { background-color: transparent; }
  &:before { background-color: darken($white, 10%); }
}

// Inner page buttons - Colours + Pseudo
a.cta-button.blue {
  background-color: $_blue;
  &:hover { background-color: $_blue; }
  &:before { background-color: darken($_blue, 10%); }
}
a.cta-button.dark-blue {
  background-color: $_dark-blue;
  &:hover { background-color: $_dark-blue; }
  &:before { background-color: darken($_dark-blue, 10%); }
}
a.cta-button.light-blue {
  background-color: $_light-blue;
  &:hover { background-color: $_light-blue; }
  &:before { background-color: darken($_light-blue, 10%); }
}
a.cta-button.yellow {
  background-color: $_yellow;
  &:hover { background-color: $_yellow; }
  &:before { background-color: darken($_yellow, 10%); }
}
a.cta-button.green  {
  background-color: $_green;
  &:hover { background-color: $_green; }
  &:before { background-color: darken($_green, 10%); }
}
a.cta-button.orange  {
  background-color: $_orange;
  &:hover { background-color: $_orange; }
  &:before { background-color: darken($_orange, 10%); }
}

// Blogs - Disable member description
.blogDetails .memberDescription {
  display: none;
}

// Donation form - Address field always visible
.donationForm .addressArea {
  display: block !important;
}


// Donation amount Other - Layout  + Format
.quickGivingPanel .formQuestion.donationAmount.donationAmountOther #donationAmountOther {
  font-size: $quick-giving-donation-amount-figure-font-size;
  font-weight: $font-weight-bold;
  padding-left: $spacer * 1.6;
}


// Donation amount Other - Currency sign always visible
.quickGivingPanel .formQuestion.donationAmountOther.donationSelected:before {
  content: '£';
  position: relative;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  display: inline-block;
  color: $body-colour;
  font-size: $quick-giving-donation-amount-figure-font-size;
  font-weight: $font-weight-bold;
  line-height: 2.1;
  z-index: 2;
}


// Footer - Logos
.footerBox[class*='ogos'] {
  position: relative;
  width: 100%;
  background-color: $white;
  max-width: 100%;
  margin: 0 auto;
  padding: $spacer #{$site-bleed / 2};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;

  // Background
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background-color: $white;
    z-index: -1;
  }

  img {
    display: block;
    margin: $spacer;
    max-width: 260px;
    max-height: 55px;
    object-fit: contain;
  }
}


.appealListingPage .tabsWrapper {
  .tab.active {
    background-color: $donate-colour;
    a { color: $white; }
  }
  .tab a {
    font-weight: $font-weight-bold;
    color: $body-colour;
  }
}


.postContent table[class*='stats']  {
  @include impact-stats  (
    $impact-stats-padding-y: 3rem,
    $impact-stats-margin-top: 3rem,
    $impact-stats-margin-bottom: 3rem,
    $impact-stats-background-image: null,
  );
}

body.PostCategory_sidebar-disabled .post .contentBlock {
  display: flex;
}

body.PostCategory_sidebar-disabled .postContent table[class*='stats'] {
  position: relative;
  display: block;
  margin: #{$spacer * 3} 0;
  width: 100vw !important;
  max-width: 100vw;
  left: calc((100vw - (#{$post-content-max-width} - #{$site-bleed * 2})) / -2);
  @media (max-width: $post-content-max-width) {
    left: -$site-bleed;
  }
  tbody {
    max-width: $post-content-max-width;
    margin: 0 auto;
  } 
}

@media (min-width: #{$nav-breakpoint + 1px}) {
  .subMenu.level3 {
    overflow-y: scroll !important;
    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }
    // Hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none;
    scrollbar-width: none;
  } 
  ul.topLevel {
    padding-right: $header-content-padding-right;
  }
}

.listedEvent.PostCategory_event-no-dates .associatedStartDate,
body.PostCategory_event-no-dates .eventWhenWrapper  {
  display: none !important;
}

.carouselControls a:before {
  font-size: 2.5rem;
}

.commentForm #terms-and-conditions-section {
  width: 100%;
}



// Inner page stats
.postContent .homefeaturecategory-homeboximpactstats {
  padding: 0;
  h2 {
    font-size: 3em;
  }
  table {
    overflow: hidden;
  }
}


// Remove GENDER from Sinup form
.yourDetailsAbout fieldset.gender {
  display: none;
}

// Button sizing
h2 a {
  font-size: $font-size-h2 !important;
}
h3 a {
  font-size: $font-size-h3 !important;
}
h4 a {
  font-size: $font-size-h4 !important;
}
h5 a {
  font-size: $font-size-h5 !important;
}
h6 a {
  font-size: $font-size-h6 !important;
}

.homeIntro {
  h1, h2, h3, h4, h5, h6 {
    font-family: $headings-font-family;
  }
}

// Visual appeals
@include appeal-field("christmas-appeal", "christmas");

// Carousel overlay not on posts
div[class*="Post"] .carouselSlide:before {
  display: none;
}

#memorialArea .listedPost {
  &:first-child {
    margin-top: 0;
  }
  margin: 0 10px;
}